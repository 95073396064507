document.querySelector('#toggle-nav').addEventListener('click', function(e){
  document.querySelector('#navigation').classList.toggle('open');
})
document.querySelectorAll('.year').forEach(function(year){

  year.addEventListener('click', function(e){
    if (!e.target.dataset.year) {return}
    e.preventDefault();
    document.querySelector('#gewinner'+ e.target.dataset.year+ '').classList.toggle('year--open')
  })
})

if (window.location.href.indexOf('#') > -1) {
  const hash = window.location.href.split('#')[1];
  let hashTop = 0;
  if (hash.indexOf('gewinner') > -1) {
    hashTop = document.querySelector(`#${hash}`).offsetTop;
    setTimeout(()=> {
      document.querySelector(`#${hash}`).classList.add('year--open');
    },100)
  } else {
    hashTop = document.querySelector(`#${hash}`).offsetTop;
  }
  window.scrollTop = hashTop;

}



const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const preloadImage = path =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({path, status: 'ok'});
    img.onerror = () => reject({path, status: 'error'});

    img.src = '/assets/images'+path;
  });
const loadImages = (...paths) => Promise.all(paths.map(preloadImage));
const images = [
 [
    "/slideshow/slide_02_image_01.jpg",
    "/slideshow/slide_02_image_02.jpg",
    "/slideshow/slide_02_image_03.jpg",
  ],
  [
    "/slideshow/slide_03_image_01.jpg",
    "/slideshow/slide_03_image_02.jpg",
    "/slideshow/slide_03_image_03.jpg",
  ],
  [
    "/slideshow/slide_04_image_01.jpg",
    "/slideshow/slide_04_image_02.jpg",
    "/slideshow/slide_04_image_03.jpg",
  ],
  [
    "/slideshow/slide_05_image_01.jpg",
    "/slideshow/slide_05_image_02.jpg",
    "/slideshow/slide_05_image_03.jpg",
  ],  [
    "/slideshow/slide_06_image_01.jpg",
    "/slideshow/slide_06_image_02.jpg",
    "/slideshow/slide_06_image_03.jpg",
  ],
  [
    "/slideshow/slide_07_image_01.jpg",
    "/slideshow/slide_07_image_02.jpg",
    "/slideshow/slide_07_image_03.jpg",
  ],
  [
    "/slideshow/slide_08_image_01.jpg",
    "/slideshow/slide_08_image_02.jpg",
    "/slideshow/slide_08_image_03.jpg",
  ],
  [
    "/slideshow/slide_09_image_01.jpg",
    "/slideshow/slide_09_image_02.jpg",
    "/slideshow/slide_09_image_03.jpg",
  ],
  [
    "/slideshow/slide_10_image_01.jpg",
    "/slideshow/slide_10_image_02.jpg",
    "/slideshow/slide_10_image_03.jpg",
  ],
  [
    "/slideshow/slide_01_image_01.jpg",
    "/slideshow/slide_01_image_02.jpg",
    "/slideshow/slide_01_image_03.jpg",
  ]
]
const siblings = n => [...n.parentElement.children].filter(c=>c.nodeType == 1 && c!=n)
async function slideshow (i) {

  const elements = document.querySelectorAll('[id^=slide-]');

  if (elements.length === 0) { return}

  await sleep(5000);
  await loadImages(...images[i]);
  const path = document.querySelector("[data-path]").dataset.path.replace("/static/", "");

  elements.forEach((element, index) => {
    const lastChild = element.querySelector('.slideshow__image');
    const copy = lastChild.cloneNode(true);
    const newPath = `${path}/images${images[i][index]}`
    lastChild.classList.add("exit");
    copy.querySelector("img").src = newPath;
    copy.classList.add("prepared");
    copy.classList.remove('slideshow__image--in', 'slideshow__image--out')
    element.appendChild(copy)
  })
  const elementsToAnimate = document.querySelectorAll('[id^=slide-]');
  elementsToAnimate.forEach((element) => {
    const newElement = element.querySelector('.prepared');
    const oldElement = element.querySelector(".exit");
    oldElement.classList.remove("slideshow__image--left","slideshow__image--right", "slideshow__image--in")
    const newElementListener = async () => {
      newElement.classList.remove('prepared')
      oldElement.remove();
      newElement.removeEventListener('animationend', newElementListener)
    }
    newElement.addEventListener('animationend', newElementListener);
    oldElement.classList.add('slideshow__image--out');
    newElement.classList.toggle('slideshow__image--in');
  })
 slideshow((i+1) % (images.length))
}
slideshow(0);


document.addEventListener("click", (evt) => {
  const navigation = document.getElementById("navigation");
  const toggleNavElem = document.querySelector('#toggle-nav')
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement == navigation || targetElement == toggleNavElem) {
      // This is a click inside. Do nothing, just return.
      return;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  // This is a click outside.
  document.querySelector('#navigation').classList.remove('open');
});
